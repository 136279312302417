import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getAccount = async () => {
  let { data } = await supabase
    .from("account_site")
    .select()
    .eq("site_uuid", JSON.parse(localStorage.getItem("device"))[1])
    .order("id");
  return await data;
};

export const checkSite = async () => {
  let { data } = await supabase
    .from("account_site")
    .select()
    .eq("site_uuid", JSON.parse(localStorage.getItem("device"))[1])
    .order("id");
  return await data;
};

export const checkDevice = async () => {
  let { data } = await supabase
    .from("site_patrol_device")
    .select()
    .eq("device_uuid", JSON.parse(localStorage.getItem("device"))[2])
    .order("id");
  return await data;
};

export const loginAuth = async (device_uuid) => {
  let { data } = await supabase
    .from("site_patrol_device")
    .select()
    .eq("device_uuid", device_uuid);
  return await data;
};

export const checkAuth = async () => {
  try {
    // const accountRes = await checkAccount();
    // console.log("account:", accountRes[0].enable);
    // if (accountRes[0].enable !== true) {
    //   return false;
    // }

    const siteRes = await checkSite();
    console.log("site:", siteRes[0].enable);
    if (siteRes[0].enable !== true) {
      return false;
    }

    const deviceRes = await checkDevice();
    console.log("device:", deviceRes[0].enable);
    if (deviceRes[0].enable !== true) {
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error in checkAuth:", error);
    return false;
  }
};
