import React, { useState } from "react";
import { Layout, Button, Modal, DatePicker } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, PrinterOutlined } from "@ant-design/icons";
import QrcodeComponent from "../../components/Qrcode";
import Init from "../../components/InitPatrol";
import { Report } from "../../services/report";

const { RangePicker } = DatePicker;

const { Content } = Layout;

const PatrolIndex = () => {
  const [size, setSize] = useState("large");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState([]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    Report({
      account_uuid: "",
      site_uuid: JSON.parse(localStorage.getItem("device"))[1],
      start_date: value[0].format("DD/MM/YYYY HH:mm"),
      end_date: value[1].format("DD/MM/YYYY HH:mm"),
      mode: "",
    }).then((res) => {
      console.log(res);
    });
    setIsOpen(false);
  };

  const onOk = (date) => {
    setValue(date);
  };

  const data = [
    {
      key: "1",
      label: "บันทึกเข้า",
      icon: <ArrowLeftOutlined className="text-[64px]" />,
      bg: "#31A627",
      color: "#FFFFFF",
      action: () => {
        setIsModalOpen(true);
      },
    },
    {
      key: "2",
      label: "บันทึกออก",
      icon: <ArrowRightOutlined className="text-[64px]" />,
      bg: "#D10000",
      color: "#FFFFFF",
      action: () => {
        setIsModalOpen(true);
      },
    },
    {
      key: "3",
      label: "ส่งรายงาน",
      icon: <PrinterOutlined className="text-[64px]" />,
      bg: "#1C689E",
      color: "#FFFFFF",
      action: () => {
        setIsOpen(true);
      },
    },
  ];

  return (
    <Content
      className="flex items-center justify-center"
      style={{ margin: "0 16px" }}
    >
      <div className="grid grid-cols-1">
        <Init />
        <Modal
          title="ส่งรายงาน"
          open={isOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="grid grid-cols-1 gap-2 items-center">
            <div>เลือกช่วงเวลา</div>
            <RangePicker
              showTime={{
                format: "HH:mm",
              }}
              placement="bottomLeft"
              format="DD-MM-YYYY HH:mm"
              onOk={onOk}
            />
          </div>
        </Modal>
        <QrcodeComponent open={isModalOpen} cancel={handleCancel} />
        {data.map((item) => (
          <Button
            key={item.key}
            onClick={item.action}
            style={{ backgroundColor: item.bg, color: item.color }}
            className="flex items-center justify-between mt-[24px] w-[280px] h-[120px] text-[24px] font-bold rounded-lg"
            icon={item.icon}
            size={size}
          >
            {item.label}
          </Button>
        ))}
      </div>
    </Content>
  );
};

export default PatrolIndex;
