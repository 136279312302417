import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getLogs = async (site_uuid) => {
  const { data } = await supabase
    .from("site_timeattendance")
    .select()
    .eq("site_uuid", site_uuid)
    .limit(20)
    .order("id", { ascending: false });
  return data;
};

export const setIn = async (payload) => {
  const { data: employee } = await supabase
    .from("site_timeattendance")
    .select()
    .eq("site_uuid", payload.site_uuid)
    .is("out", null)
    .eq("employee_idcard", payload.employee_idcard);
  if (employee.length > 0) {
    return null;
  } else {
    const { data } = await supabase
      .from("site_timeattendance")
      .insert(payload)
      .select();
    return data;
  }
};

export const updateIn = async (payload) => {
  const { data } = await supabase
    .from("site_timeattendance")
    .update({
      employee_image_in: payload.employee_image_in,
    })
    .eq("site_uuid", payload.site_uuid)
    .is("out", null)
    .eq("employee_idcard", payload.employee_idcard);
  return data;
};

export const updateOut = async (payload) => {
  const { data } = await supabase
    .from("site_timeattendance")
    .update({
      employee_image_out: payload.employee_image_out,
    })
    .select()
    .eq("site_uuid", payload.site_uuid)
    .eq("employee_uuid", payload.employee_uuid);
  return data;
};

export const setOut = async (payload) => {
  const { data: employee } = await supabase
    .from("site_timeattendance")
    .select()
    .eq("site_uuid", payload.site_uuid)
    .is("out", null)
    .eq("employee_idcard", payload.employee_idcard);
  if (employee.length === 0) {
    return null;
  } else {
    const { data } = await supabase
      .from("site_timeattendance")
      .update({ out: new Date() })
      .select()
      .eq("site_uuid", employee[0].site_uuid)
      .eq("id", employee[0].id);

    return await data;
  }
};
