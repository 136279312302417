import "./App.css";
import Header from "./components/common/Header";
import Home from "./pages/index";
import PatrolIndex from "./pages/patrol/index";
import VmsIndex from "./pages/vms/index";
import PatrolInputV1Index from "./pages/patrol/v1/input";
import TimeAttendanceInputIndex from "./pages/timeattendance/input";
import ReportInputIndex from "./pages/report/input";
import SosInputIndex from "./pages/sos/input";
import MeetingIndex from "./pages/meeting/index";
import EstampIndex from "./pages/etamp/index";

import Demo from "./pages/demo/input";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/patrol" element={<PatrolIndex />} />
        <Route path="/patrol/v1" element={<PatrolInputV1Index />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/vms" element={<VmsIndex />} />
        <Route path="/time-attendance" element={<TimeAttendanceInputIndex />} />
        <Route path="/report" element={<ReportInputIndex />} />
        <Route path="/sos" element={<SosInputIndex />} />
        <Route path="/meeting" element={<MeetingIndex />} />
        <Route path="/estamp" element={<EstampIndex />} />
      </Routes>
    </Router>
  );
}

export default App;
