import axios from "axios";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getFace = async (site_uuid) => {
  const { data } = await supabase
    .from("site_employee")
    .select()
    .eq("site_uuid", site_uuid)
    .order("id");
  return await data;
};

export const detectFace = async (image) => {
  let formData = new FormData();
  formData.append("file", image);
  return await axios.post("https://infer-face.siamai.tech/predict", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
