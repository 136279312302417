import React, { useState } from "react";
import { Layout, Button } from "antd";
import {
  ScanOutlined,
  UserSwitchOutlined,
  PrinterOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SOS } from "../services/sos";
import Init from "../components/InitPatrol";

const { Content } = Layout;

const PatrolIndex = () => {
  const [size, setSize] = useState("large");
  const navigate = useNavigate();
  const data = [
    {
      key: "1",
      label: "ระบบลาดตระเวน",
      icon: <ScanOutlined className="text-[64px]" />,
      bg: "#FFC13F",
      color: "#000000",
      action: () => {
        navigate("/patrol");
      },
    },
    {
      key: "2",
      label: "บันทึกเข้า/ออกงาน",
      icon: <UserSwitchOutlined className="text-[64px]" />,
      bg: "#31A627",
      color: "#ffff",
      action: () => {
        navigate("/time-attendance");
      },
    },
    {
      key: "2",
      label: "รายงานประจำวัน",
      icon: <PrinterOutlined className="text-[64px]" />,
      bg: "#0C07D7",
      color: "#ffff",
      action: () => {
        navigate("/report");
      },
    },
    {
      key: "3",
      label: "ติดต่อส่วนกลาง",
      icon: <PhoneOutlined className="text-[64px]" />,
      bg: "#FF0000",
      color: "#ffff",
      action: async () => {
        if (localStorage.getItem("site")) {
          await SOS({
            site_name: localStorage.getItem("site"),
            site_uuid: JSON.parse(localStorage.getItem("device"))[1],
          });
          await navigate("/sos");
        }
      },
    },
  ];

  return (
    <Content
      className="flex items-center justify-center"
      style={{ margin: "0 16px" }}
    >
      <Init />
      <div className="grid grid-cols-1">
        {data.map((item) => (
          <Button
            key={item.key}
            onClick={item.action}
            style={{ backgroundColor: item.bg, color: item.color }}
            className="flex items-center justify-between text-center mt-[24px] w-[280px] h-[120px] text-[24px] font-bold rounded-lg"
            icon={item.icon}
            size={size}
          >
            {item.label}
          </Button>
        ))}
      </div>
    </Content>
  );
};

export default PatrolIndex;
