import axios from "axios";

axios.defaults.baseURL = "https://export.siamai.tech";

export const SOS = async (data) => {
  const res = await axios.post("/sos/report", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.data;
};
