import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { updateGeoService } from "../services/geolocation";
import { getSiteService } from "../services/site";
import { loginAuth } from "../services/auth";
import { checkAuth } from "../services/auth";
import { message, Input, Modal } from "antd";
import { KeyOutlined } from "@ant-design/icons";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function error(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

function showPosition(position) {
  localStorage.setItem(
    "location",
    JSON.stringify({
      lat: position.coords.latitude,
      lon: position.coords.longitude,
    })
  );
  // message.open({
  //   type: "success",
  //   content: `Location: ${position.coords.latitude}, ${position.coords.longitude}`,
  // });
}

setInterval(() => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, error, options);
  } else {
  }
}, 1000);

const Init = () => {
  let query = useQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [device_uuid, setDevice_uuid] = React.useState("");

  const handleOk = () => {
    if (device_uuid) {
      loginAuth(device_uuid.replace(/\s+/g, ' ').trim()).then(async (res) => {
        if (res) {
          await getSiteService(res[0]["site_uuid"]).then((res) => {
            localStorage.setItem("site", res[0].site_name);
          });
          localStorage.setItem(
            "device",
            JSON.stringify({
              0: res[0]["account_uuid"],
              1: res[0]["site_uuid"],
              2: res[0]["device_uuid"],
            })
          );
          setIsModalOpen(false);
          window.location.reload();
        } else {
          messageApi.open({
            type: "error",
            content: "Access Key เพื่อเข้าสู่ระบบไม่ถูกต้อง",
          });
          setIsModalOpen(true);
        }
      });
    }
  };

  useEffect(() => {
    if (!query.get("device") && !localStorage.getItem("device")) {
      setIsModalOpen(true);
      localStorage.clear();
    } else {
      console.log("device:", query.get("device"));
      setDevice_uuid(query.get("device"));
    }

    setInterval(() => {
      const location = JSON.parse(localStorage.getItem("location"));
      if (location && localStorage.getItem("device")) {
        updateGeoService({
          site_uuid: JSON.parse(localStorage.getItem("device"))[1],
          device_uuid: JSON.parse(localStorage.getItem("device"))[2],
          lat: location.lat,
          long: location.lon,
        });
        console.log(`Location: ${location.lat}, ${location.lon}`);
        // messageApi.open({
        //   type: "success",
        //   content: `Location: ${location.lat}, ${location.lon}`,
        // });
      }
    }, 15 * 1000);

    checkAuth().then((res) => {
      console.log("auth:", res);
      if (res === false) {
        if (!localStorage.getItem("device")) {
          messageApi.open({
            type: "error",
            content: "กรอก Access Key เพื่อเข้าสู่ระบบ",
          });
          localStorage.clear();
          setIsModalOpen(true);
        } else {
          messageApi.open({
            type: "error",
            content: "ไม่พบบัญชีอุปกรณ์",
          });
          localStorage.clear();
          setIsModalOpen(true);
        }
      } else {
        messageApi.open({
          type: "success",
          content: "Authentication successful",
        });
      }
    });
  }, []);

  return (
    <>
      <div>{contextHolder}</div>
      <Modal
        title="กรอก Access Key เพื่อเข้าสู่ระบบ"
        open={isModalOpen}
        onOk={handleOk}
      >
        <Input
          onChange={(e) => {
            setDevice_uuid(e.target.value);
          }}
          value={device_uuid}
          size="large"
          placeholder="Token"
          prefix={<KeyOutlined />}
        />
      </Modal>
    </>
  );
};

export default Init;
