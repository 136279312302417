import { createClient } from "@supabase/supabase-js";
import Compressor from "compressorjs";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const uploadImageService = (file, bucket, object, checkpointName) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      maxHeight: 640,
      maxWidth: 480,
      strict: false,
      drew: function (context, canvas) {
        context.fillStyle = "rgba(145, 56, 49, 0.8)";
        const mainText = "ใช้เพื่องานรักษาความปลอดภัย";

        if (canvas.height === 640 && canvas.width === 480) {
          context.font = "5rem serif";
          const textWidth = context.measureText(mainText).width;
          const textHeight = parseInt(context.font);
          const xPosition = (canvas.width - textWidth) / 2;
          const yPosition = (canvas.height + textHeight) / 2;

          context.translate(xPosition, yPosition);
          context.rotate(-Math.PI / 3.35);

          context.fillText(mainText, -120, 250);
          context.resetTransform();
        } else {
          context.font = "2rem serif";
          const textWidth = context.measureText(mainText).width;
          const textHeight = parseInt(context.font);
          const xPosition = (canvas.width - textWidth) / 2;
          const yPosition = (canvas.height + textHeight) / 2;

          context.translate(xPosition, yPosition);

          context.fillText(mainText, 0, 0);
          context.resetTransform();
        }

        // Add date and time text at the bottom right corner
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}/${
          currentDate.getMonth() + 1
        }/${currentDate.getFullYear()}`;
        const formattedTime = `${new Date().toLocaleTimeString("th-TH")}`;
        const dateTimeText = `${checkpointName}\n${formattedDate} ${formattedTime}`;

        context.font = "1.5rem serif";
        context.fillStyle = "rgba(255,0,0)";
        const textLines = dateTimeText.split("\n");
        const lineHeight = parseInt(context.font) * 1.2;

        textLines.forEach((line, index) => {
          const textWidth = context.measureText(line).width;
          const xPosition = canvas.width - textWidth - 10;
          const yPosition =
            canvas.height - 10 - (textLines.length - index - 1) * lineHeight;
          context.fillText(line, xPosition, yPosition);
        });
      },
      success: async (compressedResult) => {
        try {
          const { data, error } = await supabase.storage
            .from(bucket)
            .upload(object, compressedResult);

          if (error) {
            reject(error);
          } else {
            resolve(data);
          }
        } catch (error) {
          reject(error);
        }
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const uploadImageFileService = (file, bucket, object) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      maxHeight: 640,
      maxWidth: 480,
      strict: false,
      success: async (compressedResult) => {
        try {
          const { data, error } = await supabase.storage
            .from(bucket)
            .upload(object, compressedResult);

          if (error) {
            reject(error);
          } else {
            resolve(data);
          }
        } catch (error) {
          reject(error);
        }
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

export const uploadImageRawService = (fileUrl, bucket, object) => {
  return new Promise((resolve, reject) => {
    // Fetch the blob from the URL
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Compress the blob
        new Compressor(blob, {
          quality: 0.6,
          maxHeight: 640,
          maxWidth: 480,
          strict: false,
          success: async (compressedResult) => {
            try {
              // Upload the compressed result to Supabase storage
              const { data, error } = await supabase.storage
                .from(bucket)
                .upload(object, compressedResult);

              if (error) {
                reject(error);
              } else {
                resolve(data);
              }
            } catch (error) {
              reject(error);
            }
          },
          error: (error) => {
            reject(error);
          },
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadLog = (payload) => {
  return new Promise((resolve, reject) => {
    supabase
      .from("upload")
      .insert(payload)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
