import React, { useState, useEffect } from "react";
import { Layout, Button, Modal, DatePicker, Input, Upload } from "antd";
import {
  ScanOutlined,
  PrinterOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import QrcodeComponent from "../../components/Qrcode";
import Init from "../../components/InitPatrol";
import { Report } from "../../services/report";
import { getCheckpointByUUIDService } from "../../services/checkpoint";
import { useNavigate } from "react-router-dom";

const { RangePicker } = DatePicker;

const { Content } = Layout;
const { TextArea } = Input;

const PatrolIndex = () => {
  const [size, setSize] = useState("large");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState({});
  const [checkpoint, setCheckpoint] = useState([]);
  const [showCheckpoint, setShowCheckpoint] = useState([]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsOpen(false);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    // convert file to base64 string

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue({ ...value, image: reader.result.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "") });
    };

    onSuccess("ok");
  };

  const haversine = (lat1, lon1, lat2, lon2) => {
    // Radius of the Earth in kilometers
    const R = 6371.0;

    // Convert latitude and longitude from degrees to radians
    const toRadians = (degrees) => degrees * (Math.PI / 180);
    lat1 = toRadians(lat1);
    lon1 = toRadians(lon1);
    lat2 = toRadians(lat2);
    lon2 = toRadians(lon2);

    // Difference in coordinates
    const dlat = lat2 - lat1;
    const dlon = lon2 - lon1;

    // Haversine formula
    const a =
      Math.sin(dlat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Distance in kilometers, converted to meters
    const distance = R * c;

    return distance * 1000; // return distance in meters
  };

  const handleOk = () => {
    Report({
      account_uuid: "",
      site_uuid: JSON.parse(localStorage.getItem("device"))[1],
      start_date: value.start_date,
      end_date: value.end_date,
      mode: "",
      name: value.name,
      message: value.message,
      image: value.image,
    }).then((res) => {
      console.log(res);
    });
    setIsOpen(false);
  };

  const data = [
    {
      key: "1",
      label: "เริ่มการเดินตรวจ",
      icon: <ScanOutlined className="text-[64px]" />,
      bg: "green",
      color: "#ffff",
      action: () => {
        setIsModalOpen(true);
      },
    },
    {
      key: "1",
      label: "เหตุผิดปกติ",
      icon: <ExclamationOutlined className="text-[64px]" />,
      bg: "#BB1616",
      color: "#ffff",
      action: () => {
        navigate(
          "/patrol/v1?device=" +
            JSON.parse(localStorage.getItem("device"))[2] +
            "&checkpoint=abnormal"
        );
      },
    },
    {
      key: "2",
      label: "ส่งรายงาน",
      icon: <PrinterOutlined className="text-[64px]" />,
      bg: "#FFC13F",
      color: "#111",
      action: () => {
        setIsOpen(true);
      },
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("device")) {
      getCheckpointByUUIDService(JSON.parse(localStorage.getItem("device"))[2])
        .then((res) => {
          setCheckpoint(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    const calculateDistance = async () => {
      setShowCheckpoint([]);
      if (checkpoint.length > 0) {
        const lat = JSON.parse(localStorage.getItem("location"))["lat"];
        const lon = JSON.parse(localStorage.getItem("location"))["lon"];

        checkpoint.map((item) => {
          if (item.checkpoint_lat === null || item.checkpoint_long === null) {
            return;
          }
          const distance = haversine(
            lat,
            lon,
            item.checkpoint_lat,
            item.checkpoint_long
          );
          setShowCheckpoint((prev) => [
            ...prev,
            {
              checkpoint_name: item.checkpoint_name,
              checkpoint_lat: item.checkpoint_lat,
              checkpoint_long: item.checkpoint_long,
              distance: distance,
            },
          ]);
        });
      }
    };
    calculateDistance();
    setInterval(() => {
      calculateDistance();
    }, 5000);
  }, [checkpoint]);

  return (
    <Content
      className="flex items-center justify-center"
      style={{ margin: "0 16px" }}
    >
      <div className="grid grid-cols-1">
        <Init />
        <Modal
          title="ส่งรายงาน"
          open={isOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="grid grid-cols-1 gap-2 items-center">
            <div>เลือกช่วงเวลา</div>
            <RangePicker
              showTime={{
                format: "HH:mm",
              }}
              inputReadOnly={true}
              placement="bottomLeft"
              format="DD-MM-YYYY HH:mm"
              onOk={(data) => {
                if (data[0] === null || data[1] === null) return;
                setValue({
                  ...value,
                  start_date: data[0].format("DD/MM/YYYY HH:mm"),
                  end_date: data[1].format("DD/MM/YYYY HH:mm"),
                });
              }}
            />
            <Upload
              customRequest={dummyRequest}
              listType="picture-card"
              maxCount={1}
              multiple
              accept="image/x-png,image/jpeg"
              capture="environment"
            >
              {value.image !=="" && "+ ยืนยันตัวตน"}
            </Upload>
            <div>ชื่อผู้รายงาน</div>
            <TextArea
              className="h-[55px]"
              placeholder="ชื่อผู้รายงาน"
              value={value.name}
              onChange={(e) => setValue({ ...value, name: e.target.value })}
            />
            <div>บันทึกข้อความ</div>
            <TextArea
              className="h-[55px]"
              placeholder="บันทึกข้อความ"
              value={value.message}
              onChange={(e) => setValue({ ...value, message: e.target.value })}
            />
          </div>
        </Modal>
        <QrcodeComponent
          open={isModalOpen}
          cancel={handleCancel}
          haversine={haversine}
        />
        {data.map((item) => (
          <Button
            key={item.key}
            onClick={item.action}
            style={{ backgroundColor: item.bg, color: item.color }}
            className="flex items-center justify-between mt-[24px] w-[280px] h-[120px] text-[24px] font-bold rounded-lg"
            icon={item.icon}
            size={size}
          >
            {item.label}
          </Button>
        ))}
        {showCheckpoint.length > 0 && (
          <div className="grid grid-cols-1 gap-2 mt-[24px]">
            <div className="text-[24px] font-bold">จุดตรวจ</div>
            {showCheckpoint
              .sort((a, b) => a.distance - b.distance)
              .map((item) => (
                <div
                  key={item.checkpoint_name}
                  className="flex items-center justify-between w-[280px] text-[18px] font-bold rounded-lg"
                >
                  <div>{item.checkpoint_name}</div>
                  <div>{item.distance.toFixed(2)} m</div>
                </div>
              ))}
          </div>
        )}
      </div>
    </Content>
  );
};

export default PatrolIndex;
