import React, { useState, useEffect } from "react";
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  VideoConference,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from "@livekit/components-react";
import "@livekit/components-styles";
import { Track } from "livekit-client";
import axios from "axios";
import Init from "../../components/InitPatrol";
import { getAccount } from "../../services/auth";

const serverUrl = "wss://siam-ai-sz5aww73.livekit.cloud";
export default function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (!localStorage.getItem("device")) return;

      getAccount().then((res) => {
        if (res[0].account_uuid) {
          axios
            .request({
              method: "POST",
              url: "https://meet-api.siamai.tech/getToken",
              data: {
                roomName: res[0].account_uuid,
                participantName: localStorage.getItem("site"),
                room_join: true,
              },
            })
            .then((res) => {
              setToken(res.data);
              console.log(res.data);
            });
        }
      });
    };
    fetchToken();
  }, []);

  return (
    <>
      <Init />
      {token && (
        <LiveKitRoom
          video={true}
          audio={true}
          token={token}
          serverUrl={serverUrl}
          data-lk-theme="default"
          style={{ height: "500px" }}
        >
          <VideoConference />
        </LiveKitRoom>
      )}
    </>
  );
}
