import React from "react";
import { Layout } from "antd";
import Nav from "../../components/common/Nav";
const { Header } = Layout;

const HeaderComponent = () => {
  return (
    <Header className="flex justify-between items-center pl-0 pr-5">
      <Nav />
      {localStorage.getItem("site") && (
        <h1 className="text-white truncate text-[16px] text-right col-span-1">
          {localStorage.getItem("site")}
        </h1>
      )}
    </Header>
  );
};

export default HeaderComponent;
