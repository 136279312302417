import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://woeexpeqouhtojnuyueg.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndvZWV4cGVxb3VodG9qbnV5dWVnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDcwNTY0MzcsImV4cCI6MjAyMjYzMjQzN30.nreTdyMMF6nP0oiZkpLN-MP5P0tV4roDMDBeHZ_va2Y"
);

export const getEtampService = async (site_uuid, VMSID) => {
  const { data } = await supabase
    .from("vms")
    .select()
    .eq("site_uuid", site_uuid)
    .eq("VMSID", VMSID)
    .is("estamp_name", null)
    .order("id", { ascending: false })
    .limit(1);
  return await data;
};

export const updateEstampService = async (data) => {
  const { error } = await supabase
    .from("vms")
    .update({
      estamp_name: data.estamp_name,
      estamp_datetime: data.estamp_datetime,
    })
    .eq("VMSID", data.VMSID)
    .eq("site_uuid", data.site_uuid);
  return error;
};
