import React, { useState, useEffect } from "react";
import { LiveKitRoom, VideoConference } from "@livekit/components-react";
import "@livekit/components-styles";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { useSearchParams } from "react-router-dom";

const serverUrl = "wss://siam-ai-sz5aww73.livekit.cloud";
export default function App() {
  const [token, setToken] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchToken = async () => {
      const account_uuid = atob(searchParams.get("id"));
      const name = searchParams.get("name");
      if (!account_uuid) return;
      axios
        .request({
          method: "POST",
          url: "https://meet-api.siamai.tech/getToken",
          data: {
            roomName: account_uuid,
            participantName: name ? name : uuid(),
            room_join: true,
          },
        })
        .then((res) => {
          setToken(res.data);
        });
    };
    fetchToken();
  }, []);

  return (
    <>
      {token && (
        <LiveKitRoom
          video={true}
          audio={true}
          token={token}
          serverUrl={serverUrl}
          data-lk-theme="default"
          style={{ height: "500px" }}
        >
          <VideoConference />
        </LiveKitRoom>
      )}
    </>
  );
}
