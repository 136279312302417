import React, { useState, useRef } from "react";
import { QrReader } from "react-qr-reader";
import { Modal, Button } from "antd";
import { SunOutlined, MoonOutlined } from "@ant-design/icons";
import { getCheckpointByCheckpointService } from "../services/checkpoint";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const QrcodeComponent = ({ open, cancel, haversine }) => {
  const [data, setData] = useState({});
  const [title, setTitle] = useState("สแกนจุดตรวจ: ");
  const [messageApi, contextHolder] = message.useMessage();
  const [on, setOn] = useState(false);

  const [selected, setSelected] = useState("environment");
  const navigate = useNavigate();

  const handleOk = () => {
    if (data?.checkpoint_uuid) {
      messageApi.open({
        type: "success",
        content: "เริ่มตรวจสอบจุดตรวจ " + data?.checkpoint_name,
      });
      navigate(
        "/patrol/v1?device=" +
          JSON.parse(localStorage.getItem("device"))[2] +
          "&checkpoint=" +
          data?.checkpoint_uuid
      );
      cancel();
      setOn(false);
    } else {
      messageApi.open({
        type: "error",
        content: "กรุณาสแกนจุดตรวจ",
      });
      setOn(false);
    }
  };

  return (
    <div>
      {contextHolder}
      <Modal
        title={title}
        open={open}
        onOk={handleOk}
        onCancel={() => {
          setOn(false);
          cancel();
        }}
      >
        <div className="flex items-center justify-center">
          <Button
            onClick={() => {
              setOn(!on);
            }}
            className={`text-[30px] ${
              on
                ? "bg-[#FFC13F] text-[#00204A] "
                : "bg-[#00204A] text-[#FFFFFF]"
            }`}
            icon={on ? <MoonOutlined /> : <SunOutlined />}
            style={{
              borderRadius: "10px",
              width: "80%",
              height: "80px",
            }}
          >
            {on ? "ปิดแฟลช" : "เปิดแฟลช"}
          </Button>
        </div>
        {on && (
          <QrReader
            constraints={{ facingMode: selected, torch: true }}
            scanDelay={1}
            onResult={(result, error) => {
              if (!!result) {
                getCheckpointByCheckpointService(result?.text).then((res) => {
                  if (res === undefined) {
                    messageApi.open({
                      type: "error",
                      content: "ไม่พบข้อมูลจุดตรวจ",
                    });
                    return;
                  }
                  const lat = JSON.parse(localStorage.getItem("location"))[
                    "lat"
                  ];
                  const lon = JSON.parse(localStorage.getItem("location"))[
                    "lon"
                  ];
                  if (res.checkpoint_lat && res.checkpoint_long) {
                    const distance = haversine(
                      res.checkpoint_lat,
                      res.checkpoint_long,
                      lat,
                      lon
                    );
                    console.log(distance, res.checkpoint_distance_limit);
                    if (
                      distance > res.checkpoint_distance_limit &&
                      res.checkpoint_distance_limit !== 0
                    ) {
                      messageApi.open({
                        type: "error",
                        content: "ไม่อยู่ในระยะที่กำหนด",
                      });
                      return;
                    }
                  }

                  setData(res);
                  setTitle("สแกนจุดตรวจ: " + res?.checkpoint_name);
                });
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100px", height: "100px" }}
          />
        )}
        {!on && (
          <QrReader
            constraints={{ facingMode: selected, torch: true }}
            scanDelay={1}
            onResult={(result, error) => {
              if (!!result) {
                getCheckpointByCheckpointService(result?.text).then((res) => {
                  if (res === undefined) {
                    messageApi.open({
                      type: "error",
                      content: "ไม่พบข้อมูลจุดตรวจ",
                    });
                    return;
                  }
                  
                  const lat = JSON.parse(localStorage.getItem("location"))[
                    "lat"
                  ];
                  const lon = JSON.parse(localStorage.getItem("location"))[
                    "lon"
                  ];
                  if (res.checkpoint_lat && res.checkpoint_long) {
                    const distance = haversine(
                      res.checkpoint_lat,
                      res.checkpoint_long,
                      lat,
                      lon
                    );
                    console.log(distance, res.checkpoint_distance_limit);
                    if (
                      distance > res.checkpoint_distance_limit &&
                      res.checkpoint_distance_limit !== 0
                    ) {
                      messageApi.open({
                        type: "error",
                        content: "ไม่อยู่ในระยะที่กำหนด",
                      });
                      return;
                    }
                  }

                  setData(res);
                  setTitle("สแกนจุดตรวจ: " + res?.checkpoint_name);
                });
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100px", height: "100px" }}
          />
        )}
      </Modal>
    </div>
  );
};

export default QrcodeComponent;
