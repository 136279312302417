import React, { useState } from "react";
import { Button, Drawer } from "antd";

import {
  HomeOutlined,
  NotificationOutlined,
  MessageOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const BottomComponent = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [placement, setPlacement] = useState("left");

  const items = [
    {
      key: "1",
      label: "หน้าแรก",
      icon: <HomeOutlined />,
      action: () => {
        window.location.href = "/";
      },
    },
    {
      key: "1",
      label: "แจ้งเตือน",
      icon: <NotificationOutlined />,
      action: () => {
        setCollapsed(false);
      },
    },
    {
      key: "2",
      label: "แชท",
      icon: <MessageOutlined />,
      action: () => {
        setCollapsed(false);
      },
    },
    {
      key: "3",
      label: "ออกจากระบบ",
      icon: <LogoutOutlined />,
      action: () => {
        localStorage.clear();
        window.location.href = "/";
      },
    },
  ];

  return (
    <>
      <Drawer
        title="เมนู"
        width={200}
        placement={placement}
        closable={false}
        onClose={() => setCollapsed(!collapsed)}
        open={collapsed}
        key={placement}
      >
        {items.map((item) => (
          <div key={item.key} className="flex items-center p-2">
            {item.icon}
            <span onClick={item.action} className="ml-2">
              {item.label}
            </span>
            <br />
          </div>
        ))}
      </Drawer>
      <div className="flex items-center justify-l">
        <Button
          type="text"
          className="text-white hover:text-white"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
          type="primary"
          className="bg-[#29be1e] border-[#29be1e]"
        >
          หน้าหลัก
        </Button>
      </div>
    </>
  );
};

export default BottomComponent;
