import React, { useState, useEffect, useMemo } from "react";
import { QrReader } from "react-qr-reader";
import { Modal, Button, Typography, Row, Col, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { getSiteService } from "../../services/site";
import { getEtampService, updateEstampService } from "../../services/etamp";
import moment from "moment";

const { Title } = Typography;

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const QrScanModal = ({ visible, onClose, onResult }) => (
  <Modal
    title="สแกนบัตร"
    open={visible}
    footer={null}
    onCancel={onClose}
    centered={false}
    style={{ top: 20 }}
  >
    <QrReader
      constraints={{ facingMode: "environment" }}
      delay={300}
      onResult={onResult}
      style={{ width: "100%" }}
    />
  </Modal>
);

var state = false;

const Estamp = () => {
  const [title, setTitle] = useState("สแกนบัตร");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [siteInfo, setSiteInfo] = useState({});
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const query = useQuery();

  const checkText = (text) => {
    const contractorPattern = /CON/;
    const supplierPattern = /SUP/;
    const visitorPattern = /VIS/;

    const containsContractor = contractorPattern.test(text);
    const containsSupplier = supplierPattern.test(text);
    const containsVisitor = visitorPattern.test(text);

    return {
      containsContractor,
      containsSupplier,
      containsVisitor,
    };
  };

  const handleResult = (result) => {
    const { containsContractor, containsSupplier, containsVisitor } =
      checkText(result);
    if ((containsContractor || containsSupplier || containsVisitor) && !state) {
      setTitle("สแกนบัตร: สำเร็จ");
      getEtampService(siteInfo["site_uuid"], result).then((res) => {
        if (res.length) {
          setData(res[0]);
          setIsModalOpen(false);
          state = true;
        } else {
          setTitle("ไม่พบข้อมูล");
          setIsModalOpen(false);
          state = false;
        }
      });
    }
  };

  const stamp = () => {
    updateEstampService({
      VMSID: data.VMSID,
      site_uuid: siteInfo.site_uuid,
      estamp_name: siteInfo.destination,
      estamp_datetime: new Date(),
    }).then((res) => {
      setData(null);
      setIsModalOpen(false);
      setTitle("สแกนบัตร");
    });
  };

  useEffect(() => {
    const data = JSON.parse(atob(query.get("id")));
    getSiteService(data.site).then((res) => {
      setSiteInfo({
        site: res[0].site_name,
        site_uuid: data.site,
        destination: data.destination,
      });
      setLoading(false);
    });
  }, [query]);

  return (
    <div className=" bg-gray-100 flex items-top justify-center p-4">
      <QrScanModal
        visible={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          state = false;
        }}
        onResult={handleResult}
      />
      <Row className="flex flex-col items-center w-full max-w-md bg-white p-6 shadow-lg rounded-lg">
        <Title level={3}>{title}</Title>
        <Col className="w-full text-center">
          {loading ? (
            <Spin size="large" className="my-6" />
          ) : (
            <>
              <div className="text-lg my-2">
                <b>หน่วยงาน:</b> {siteInfo.site || "Loading..."}
              </div>
              <div className="text-lg my-2">
                <b>แผนก:</b> {siteInfo.destination || "Loading..."}
              </div>
            </>
          )}
        </Col>
        {!data && (
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setIsModalOpen(true);
              state = false;
            }}
            className="mt-6 w-full text-lg font-bold rounded-lg"
          >
            คลิกเพื่อสแกน
          </Button>
        )}
        {data && (
          <div className="mt-6 w-full text-center">
            <Title level={4}>ข้อมูล</Title>
            <div className="text-lg my-2">
              <b>หมายเลขบัตร:</b> {data.VMSID}
            </div>
            <div className="text-lg my-2">
              <b>ชื่อ:</b> {data.metadata?.name}
            </div>
            <div className="text-lg my-2">
              {data.enter_name}{" "}
              {moment(new Date(data.enter_datetime)).format("DD/MM/YYYY HH:mm")}
            </div>
            <Row justify="center" gutter={16} className="mt-4">
              <Col span={12}>
                <Button
                  type="primary"
                  size="large"
                  onClick={stamp}
                  className="w-full text-lg font-bold rounded-lg"
                >
                  ยืนยัน
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="default"
                  size="large"
                  onClick={() => {
                    setIsModalOpen(false);
                    setTitle("สแกนบัตร");
                    setData(null);
                    state = false;
                  }}
                  className="w-full text-lg font-bold rounded-lg"
                >
                  ยกเลิก
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Row>
    </div>
  );
};

export default Estamp;
